import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl } from '../../api';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate, useLocation } from 'react-router-dom';

const YogaBlogPost = () => {
    const [slides, setSlides] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        axios.get(`${baseurl}/api/blog`)
            .then(response => {
                console.log(response.data);
                setSlides(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const reloadBlogdetailPage = (id) => {
        navigate(`/blogdetails/${id}`);
        window.scrollTo(0, 0);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const reloadPage = (page) => {
        navigate(page);
        window.scrollTo(0, 0);
    };

    return (
        <section style={{ padding: '30px 0', backgroundColor: '#e6f2f0' }}>
            <div className='container'>
                <div className="position-relative">
                    <div className="pbmit-heading-subheading animation-style2">
                        <h4 className="pbmit-subtitle">blogs</h4>
                        <h2 className="pbmit-title">Recent blogs</h2>
                    </div>
                    <div className="tab_btn">
                        <a
                            className="pbmit-btn pbmit-btn-outline"
                            onClick={() => reloadPage("/blog")}
                            data-magnetic=""
                        >
                            <span className="pbmit-btn-content-wrapper">
                                <span className="pbmit-button-text">View All</span>
                            </span>
                        </a>
                    </div>
                </div>
                <div>
                    <section className="testimonial_two section-xl">
                        <div className="container">
                            <Slider {...settings}>
                                {slides.map(blog => (
                                    <div
                                        className="testimonial-two_bg"
                                        key={blog._id}
                                        style={{
                                            height: '300px', // Adjusted height if needed
                                            padding: '20px'
                                        }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-md-12 col-xl-6 pbmit-col_1">
                                                <div
                                                    className="testimonial-two-left_img"
                                                    style={{
                                                        backgroundImage: `url(${blog.image})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        height: '180px', // Adjusted height if needed
                                                        width: '100%',
                                                        borderRadius: '25px',
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-12 col-xl-6 pbmit-col_2">
                                                <div className="testimonial-two_rightbox" style={{ height: '180px' }}>
                                                    <div className="pbmit-heading-subheading text-white animation-style2">
                                                        <h3 className="pbmit-subtitle">{blog.tagline}</h3>
                                                        <h4 className="pbmit-title">{blog.heading}</h4>
                                                    </div>
                                                    <blockquote className="pbminfotech-testimonial-text">
                                                        <p>
                                                            {blog.description.length > 250
                                                                ? `${blog.description.substring(0, 250)}...`
                                                                : blog.description}
                                                        </p>
                                                    </blockquote>
                                                    <button
                                                        onClick={() => reloadBlogdetailPage(blog._id)}
                                                        className="pbmit-btn pbmit-btn-outline"
                                                    >
                                                        Read More
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

export default YogaBlogPost;
