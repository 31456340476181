import React from 'react'
import { useNavigate } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/event');
    window.scrollTo(0, 0);
    window.location.reload();
  };

  return (
    <div>
      <footer className="site-footer">
        <div className="footer-top-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8 pbmit-col_1">
                <ul className="pbmit-icon-list-items pbmit-inline-items">
                  <li className="pbmit-icon-list-item pbmit-inline-item">
                    <a>
                      <span className="pbmit-icon-list-text" onClick={handleClick} style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} >Event</span>
                    </a>
                  </li>
                  <li className="pbmit-icon-list-item pbmit-inline-item">
                    <a>
                      <span className="pbmit-icon-list-text" onClick={handleClick} style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} >Schedule</span>
                    </a>
                  </li>
                  <li className="pbmit-icon-list-item pbmit-inline-item">
                    <a>
                      <span className="pbmit-icon-list-text" onClick={handleClick} style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} >Retreat</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 pbmit-col_2">
                <div className="pbmit-ihbox-style-13">
                  <div className="pbmit-ihbox-box d-flex align-items-center">
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper">
                        <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                          <i className="pbmit-yoge-icon pbmit-yoge-icon-phone-call" />
                        </div>
                      </div>
                    </div>
                    <div className="pbmit-ihbox-contents">
                      <h2 className="pbmit-element-title">+91 91524-82025</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 pbmit-col_3">
                <div className="pbmit-ihbox-style-13">
                  <div className="pbmit-ihbox-box d-flex align-items-center">
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper">
                        <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                          <i className="pbmit-yoge-icon pbmit-yoge-icon-email" />
                        </div>
                      </div>
                    </div>
                    <div className="pbmit-ihbox-contents">
                      <h2 className="pbmit-element-title">
                        <a
                          // href="https://yoge-demo.pbminfotech.com/cdn-cgi/l/email-protection"
                          className="__cf_email__"
                        // data-cfemail="523b3c343d122b3d3533313320377c313d3f"
                        >
                          support@trika.co.in
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pbmit-footer-wrapper">
          <div className="footer-wrap pbmit-footer-big-area">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xl-4" />
                <div className="col-md-12 col-xl-8 pbmit-footer-right">
                  <h3 className="pbmit-title">
                    Return Home to Yourself: Rediscover Your Essence
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="pbmit-footer-widget-area">
            <div className="container">
              <div className="row">
                <div className="pbmit-footer-widget-col-1 col-md-6 col-lg-3">
                  <aside className="widget widget_text">
                    <div className="textwidget">
                      <div className="pbmit-footer-logo">
                        <img src="images/TRIKA YOGA.png" alt="" />
                      </div>
                      <div className="pbmit-footer-text">
                        The body is your temple. Keep it pure and clean
                        for the soul to reside in.
                      </div>
                      <ul className="pbmit-social-links">
                        <li className="pbmit-social-li pbmit-social-facebook">
                          <a
                            title="Facebook"
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noopener"
                          >
                            <span>
                              <i className="pbmit-base-icon-facebook-f" />
                            </span>
                          </a>
                        </li>
                        <li className="pbmit-social-li pbmit-social-twitter">
                          <a
                            title="Twitter"
                            href="https://www.twitter.com/"
                            target="_blank"
                            rel="noopener"
                          >
                            <span>
                              <i className="pbmit-base-icon-twitter-2" />
                            </span>
                          </a>
                        </li>
                        <li className="pbmit-social-li pbmit-social-linkedin">
                          <a
                            title="LinkedIn"
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noopener"
                          >
                            <span>
                              <i className="pbmit-base-icon-linkedin-in" />
                            </span>
                          </a>
                        </li>
                        <li className="pbmit-social-li pbmit-social-instagram">
                          <a
                            title="Instagram"
                            href="https://www.instagram.com/"
                            target="_blank"
                            rel="noopener"
                          >
                            <span>
                              <i className="pbmit-base-icon-instagram" />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
                {/* <div className="pbmit-footer-widget-col-2 col-md-6 col-lg-3">
                    <div className="widget">
                      <h2 className="widget-title">Useful Link</h2>
                      <div className="textwidget">
                        <ul>
                          <li>
                            <a href="#">Company</a>
                          </li>
                          <li>
                            <a href="about-us.html">About</a>
                          </li>
                          <li>
                            <a href="contact-us.html">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="pbmit-footer-widget-col-3 col-md-6 col-lg-3">
                    <div className="widget">
                      <h2 className="widget-title">Working Time</h2>
                      <div className="pbmit-timelist-wrapper">
                        <ul className="pbmit-timelist-list">
                          <li>
                            <span className="pbmit-timelist-li-title">
                              Mon - Fri: 9.00am - 5.00pm
                            </span>
                          </li>
                          <li>
                            <span className="pbmit-timelist-li-title">
                              Saturday: 10.00am - 6.00pm
                            </span>
                          </li>
                          <li>
                            <span className="pbmit-timelist-li-title">
                              Sunday Closed
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                <div className="pbmit-footer-widget-col-4 col-md-6 col-lg-3">
                  <aside className="widget">
                    <h2 className="widget-title">Our address</h2>
                    <div className="pbmit-contact-widget-line pbmit-contact-widget-address">
                      Vaikunthlal Mehta Rd, <br /> JVPD Scheme, Juhu, Mumbai, Maharashtra 400049
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <div className="pbmit-footer-text-area">
            <div className="container">
              <div className="pbmit-footer-text-inner">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pbmit-footer-copyright-text-area">
                      {" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ul className="pbmit-footer-menu">
                      <li className="menu-item">
                        <a href="#">Terms and conditions</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Privacy policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
