import React from 'react'

function Faq() {
    return (
        <div>
            <div className="page-content faq_section">
                {/* Faq Start */}
                <section className="section-xl">
                    <div className="container">
                        <div className="pbmit-heading-subheading">
                            <h4 className="pbmit-subtitle"> FAQ</h4>
                            <h2 className="pbmit-title">Happy to Answer All Your Questions</h2>
                        </div>
                        <div className="accordion" id="accordionExample2">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading02">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse02" aria-expanded="false" aria-controls="collapse02">
                                        <span className="pbmit-accordion-icon pbmit-accordion-icon-right">
                                            <span className="pbmit-accordion-icon-closed">
                                                <i className="fa fa-angle-up" />
                                            </span>
                                            <span className="pbmit-accordion-icon-opened">
                                                <i className="fa fa-angle-down" />
                                            </span>
                                        </span>
                                        <span className="pbmit-accordion-title">
                                            <span className="pbmit-black-color">01.</span>
                                            I’m not sure what kind of application development services I need. What should I do?
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapse02" className="accordion-collapse collapse" aria-labelledby="heading02" data-bs-parent="#accordionExample2">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading03">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse03" aria-expanded="false" aria-controls="collapse03">
                                        <span className="pbmit-accordion-icon pbmit-accordion-icon-right">
                                            <span className="pbmit-accordion-icon-closed">
                                                <i className="fa fa-angle-up" />
                                            </span>
                                            <span className="pbmit-accordion-icon-opened">
                                                <i className="fa fa-angle-down" />
                                            </span>
                                        </span>
                                        <span className="pbmit-accordion-title">
                                            <span className="pbmit-black-color">02.</span>
                                            Do we have a pilot project as our software development services?
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapse03" className="accordion-collapse collapse" aria-labelledby="heading03" data-bs-parent="#accordionExample2">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading04">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse04" aria-expanded="false" aria-controls="collapse04">
                                        <span className="pbmit-accordion-icon pbmit-accordion-icon-right">
                                            <span className="pbmit-accordion-icon-closed">
                                                <i className="fa fa-angle-up" />
                                            </span>
                                            <span className="pbmit-accordion-icon-opened">
                                                <i className="fa fa-angle-down" />
                                            </span>
                                        </span>
                                        <span className="pbmit-accordion-title">
                                            <span className="pbmit-black-color">03.</span>
                                            What advantages of outsourcing your project to our team you get?
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapse04" className="accordion-collapse collapse" aria-labelledby="heading04" data-bs-parent="#accordionExample2">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading05">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse05" aria-expanded="false" aria-controls="collapse05">
                                        <span className="pbmit-accordion-icon pbmit-accordion-icon-right">
                                            <span className="pbmit-accordion-icon-closed">
                                                <i className="fa fa-angle-up" />
                                            </span>
                                            <span className="pbmit-accordion-icon-opened">
                                                <i className="fa fa-angle-down" />
                                            </span>
                                        </span>
                                        <span className="pbmit-accordion-title">
                                            <span className="pbmit-black-color">04.</span>
                                            What is the budget generally needed? How do you estimate the cost of a software project?
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapse05" className="accordion-collapse collapse" aria-labelledby="heading05" data-bs-parent="#accordionExample2">
                                    <div className="accordion-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Faq End */}
            </div>
        </div>
    )
}

export default Faq
