import React from 'react'
import { useParams } from 'react-router-dom';
import Header from '../Layout/Header';
import Banner from '../About/Banner';
import Footer from '../Layout/Footer';

function RetreatDetails() {
    const { id } = useParams(); // Retrieve the ID from the route parameter

    return (
        <div>
            <Header />
            <Banner />
            <div className="page-content">
                <section className="site_content single_event_listing">
                    <div className="container">
                        <div className="pbmit-single-event-wrapper">
                            <div className="pbmit-single-event-header-top">
                                <img src="images/event-box/event-single-01.jpg" alt="" />
                            </div>
                            <div className="pbmit-single-event-body">
                                <div className="row">
                                    <div className="col-md-8 pbmit-single-event-left-content">
                                        <div className="pbmit-single-event-short-info">
                                            <div className="pbmit-event-details">
                                                <div className="pbmit-event-organizer d-flex align-items-center">
                                                    <div className="pbmit-event-meta-line pbmit-event-time d-flex">
                                                        <i className="pbmit-base-icon-time" />
                                                        <div className="pbmit-event-meta-time">50 min / Session</div>
                                                    </div>
                                                    <div className="pbmit-event-meta-line pbmit-event-registration-date d-flex">
                                                        <i className="pbmit-base-icon-calendar-3" />
                                                        <span className="pbmit-event-date-text">08-11-2023</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pbmit-single-event-body-content">
                                            <p className="pbmit-firstletter">We Close the right nostril and inhale twenty rapid bellows-like breaths through the left nostril. Repeat with twenty more bellows breath to through the right nostril while keeping the left nostril closed. Proceed to take twenty bellows <u><a className="pbmit-global-color" href="#">breaths through both nostrils.</a></u> As you are breathe in and out, engage your abdominal muscles. On an inhale, pulls abdominal muscles toward your spine and maintain that engagement as you exhale, can reduce symptoms of depression when combined with Iyengar yoga.</p>
                                            <p>If you’re dealing with high levels of stress or anxiety, there are several types of breathing techniques that may help you feel calmer and more relaxed. It’s easy to get started. All you need is a quiet space where you can pay attention to your breathing. Breathing exercises don’t have to take a lot of time out of your day. It’s just about setting aside time to pay <u><a className="pbmit-global-color" href="#">attention to your breathing.</a></u> Here are a few ideas to get started You can practice pursed lip breathing at any time. It may be especially useful during activities such as bending, lifting, or stair climbing. Practice using this breath 4 to 5 times a day when you begin so that you can correctly learn the breathing pattern.Alternate nostril breathing has been shown to enhance cardiovascular function and lower heart rate.</p>
                                            <p>Equal breathing is known as Sama Vritti in Sanskrit. This breathing technique focuses on making your inhales and exhales the same a length. Making your breath smooth and steady can help bring about balance and equanimity.Research on older adults with high blood have pressure showed that this technique may help improve mental well-being and increase the oxygen supply to the brain and lungs. Slightly extend your breath in length but don’t force it.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pbmit-single-event-right-content">
                                        <div className="pbmit-single-event-body-sidebar">
                                            <div className="pbmit-tab-style-1 event_registration">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-2-1" aria-selected="true" role="tab">
                                                            <span>Register for event</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane show" id="tab-2-1" role="tabpanel">
                                                        <div className="pbmit-column-inner">
                                                            <p>To register for this event <strong>email your details to</strong> <a href="https://yoge-demo.pbminfotech.com/cdn-cgi/l/email-protection#a2c1cdccd6c3c1d6e2d2c0cfcbccc4cdd6c7c1ca8cc1cdcf9dd1d7c0c8c7c1d69ff0c7c5cbd1d6d0c3d6cbcdcc879092d4cbc3879092879090eac3d6cac3879092fbcdc5c3879092f6d0c3cbcccbccc5879090879092cecbd1d6cbccc5879092cdcc879092cad6d6d2d18791e38790e48790e4dbcdc5c78fc6c7cfcd8cd2c0cfcbccc4cdd6c7c1ca8cc1cdcf8790e4c6c7cfcd93"><span className="__cf_email__" data-cfemail="c5a6aaabb1a4a6b185b5a7a8acaba3aab1a0a6adeba6aaa8">[email&nbsp;protected]</span></a> </p>
                                                            <p>
                                                                Register using webmail: <a href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@pbminfotech.com&su=Registration+via+%22Hatha+Yoga+Training%22+listing+on+https%3A%2F%2Fyoge-demo.pbminfotech.com%2Fdemo1">Gmail</a> /
                                                                <a href="http://webmail.aol.com/Mail/ComposeMessage.aspx?to=contact@pbminfotech.com&subject=Registration+via+%22Hatha+Yoga+Training%22+listing+on+https%3A%2F%2Fyoge-demo.pbminfotech.com%2Fdemo1">AOL</a> /
                                                                <a href="http://compose.mail.yahoo.com/?to=contact@pbminfotech.com&subject=Registration+via+%22Hatha+Yoga+Training%22+listing+on+https%3A%2F%2Fyoge-demo.pbminfotech.com%2Fdemo1">Yahoo</a> /
                                                                <a href="https://outlook.live.com/mail/0/deeplink/compose?to=contact@pbminfotech.com&subject=Registration+via+%22Hatha+Yoga+Training%22+listing+on+https%3A%2F%2Fyoge-demo.pbminfotech.com%2Fdemo1">Outlook</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pbmit-single-event-sidebar-info">
                                                <div className="clearfix">&nbsp;</div>
                                                <h3 className="pbmit-heading-text">Date And Time</h3>
                                                <div className="pbmit-event-date-time">
                                                    <span className="pbmit-event-date-time-text">14-02-2024</span> to <br /> <span className="pbmit-event-date-time-text">14-02-2024</span>
                                                </div>
                                                <div>
                                                    <div className="clearfix">&nbsp;</div>
                                                    <h3 className="pbmit-heading-text">Location</h3>
                                                    <div>Online event</div>
                                                </div>
                                                <div className="clearfix">&nbsp;</div>
                                                <h3 className="pbmit-heading-text">Event Category</h3>
                                                <div className="pbmit-event-category">
                                                    <a href="event-style-1.html">
                                                        <span className="pbmit-event-category-text">Skin Quality</span>
                                                    </a>
                                                </div>
                                                <div className="clearfix">&nbsp;</div>
                                                <h3 className="pbmit-heading-text">Event Visitor</h3>
                                                <div className="pbmit-event-visitor">
                                                    <div className="pbmit-event-meta-eventvisitor">Pranayam Expert</div>
                                                </div>
                                                <div className="pbmit-event-share-btn">
                                                    <div className="pbmit-share-icon-wrapper">
                                                        <span className="pbmit-share-icon">
                                                            <i className="pbmit-base-icon-share-2" />
                                                        </span>
                                                        <ul className="pbmit-social-links">
                                                            <li className="pbmit-social-li pbmit-social-facebook">
                                                                <a title="Facebook" href="#" target="_blank">
                                                                    <span><i className="pbmit-base-icon-facebook-f" /></span>
                                                                </a>
                                                            </li>
                                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                                <a title="Twitter" href="#" target="_blank">
                                                                    <span><i className="pbmit-base-icon-twitter-2" /></span>
                                                                </a>
                                                            </li>
                                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                                <a title="LinkedIn" href="#" target="_blank">
                                                                    <span><i className="pbmit-base-icon-linkedin-in" /></span>
                                                                </a>
                                                            </li>
                                                            <li className="pbmit-social-li pbmit-social-instagram">
                                                                <a title="Instagram" href="#" target="_blank">
                                                                    <span><i className="pbmit-base-icon-instagram" /></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="pbmit-sticky-corner pbmit-bottom-left-corner">
                                                            <svg width={30} height={30} viewBox="0 0 30 30" fill xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="red" />
                                                            </svg>
                                                        </div>
                                                        <div className="pbmit-sticky-corner pbmit-top-right-corner">
                                                            <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="red" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-single-event-footer">
                                <div className="accordion" id="accordionExample1">
                                    <div className="accordion-item active" id="headingOne1">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                                                <span className="pbmit-accordion-icon pbmit-accordion-icon-right">
                                                    <span className="pbmit-accordion-icon-closed">
                                                        <i className="fa fa-plus" />
                                                    </span>
                                                    <span className="pbmit-accordion-icon-opened">
                                                        <i className="fa fa-minus" />
                                                    </span>
                                                </span>
                                                <span className="pbmit-accordion-title">Organizer</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne1" className="accordion-collapse collapse show" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                            <div className="accordion-body">
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="pbmit-organizer-logo">
                                                            <img src="images/team/team-img-05b.jpg" className="img-fluid" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="pbmit-heading-text">
                                                            <span>Paula Deen</span>
                                                        </div>
                                                        <div className="pbmit-organizer-description">
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        </div>
                                                        <div className="pbmit-organizer-social-links">
                                                            <div className="pbmit-organizer-social-lists">
                                                                <div className="pbmit-social-icon pbmit-weblink">
                                                                    <a href="http://www.pauladeen.com/" title="Get Connect on Website" target="_blank">
                                                                        <i className="pbmit-base-icon-link" />
                                                                    </a>
                                                                </div>
                                                                <div className="pbmit-social-icon pbmit-facebook">
                                                                    <a href="https://www.facebook.com/pauladeen" title="Get Connect on Facebook" target="_blank">
                                                                        <i className="pbmit-base-icon-facebook-f" />
                                                                    </a>
                                                                </div>
                                                                <div className="pbmit-social-icon pbmit-instagram">
                                                                    <a href="https://www.instagram.com/pauladeen" title="Get Connect on Instagram" target="_blank">
                                                                        <i className="pbmit-base-icon-instagram" />
                                                                    </a>
                                                                </div>
                                                                <div className="pbmit-social-icon pbmit-twitter">
                                                                    <a href="https://www.twitter.com/pauladeen" title="Get Connect on Twitter" target="_blank">
                                                                        <i className="pbmit-base-icon-twitter-2" />
                                                                    </a>
                                                                </div>
                                                                <div className="pbmit-social-icon pbmit-youtube">
                                                                    <a href="https://www.youtube.com/channel/pauladeen" title="Get Connect on Youtube" target="_blank">
                                                                        <i className="pbmit-base-icon-youtube-play" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pbmit-organizer-contact-actions">
                                                            <a href="#"><span>More info</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item active" id="headingTwo">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <span className="pbmit-accordion-icon pbmit-accordion-icon-right">
                                                    <span className="pbmit-accordion-icon-closed">
                                                        <i className="fa fa-plus" />
                                                    </span>
                                                    <span className="pbmit-accordion-icon-opened">
                                                        <i className="fa fa-minus" />
                                                    </span>
                                                </span>
                                                <span className="pbmit-accordion-title">Venue</span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                            <div className="accordion-body">
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="pbmit-organizer-logo">
                                                            <img src="images/venue-2.jpg" className="img-fluid" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="pbmit-heading-text">
                                                            <span>Orlando</span>
                                                        </div>
                                                        <div className="pbmit-organizer-description">
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
            <div
                className="pbmit-progress"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                style={{
                    position: 'fixed',
                    bottom: '30px',
                    right: '30px',
                    width: '50px',
                    height: '50px',
                    backgroundColor: '#b4a9c9',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    zIndex: 1000,
                    opacity: 0.7,
                }}
                onMouseOver={(e) => e.currentTarget.style.opacity = 1}
                onMouseOut={(e) => e.currentTarget.style.opacity = 0.7}
            >
                <svg
                    className="pbmit-progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path
                        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        style={{
                            fill: 'none',
                            stroke: 'white',
                            strokeWidth: 5,
                        }}
                    />
                </svg>
                {/* Arrow SVG */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    viewBox="0 0 24 24"
                    style={{
                        position: 'absolute',
                        width: '20px',
                        height: '20px',
                    }}
                >
                    <path
                        d="M12 19c-.55 0-1-.45-1-1V8.41L6.71 12.7a.996.996 0 1 1-1.41-1.41l6-6a.996.996 0 0 1 1.41 0l6 6a.996.996 0 1 1-1.41 1.41L13 8.41V18c0 .55-.45 1-1 1z"
                    />
                </svg>
            </div>
        </div>
    )
}

export default RetreatDetails
