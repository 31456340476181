import React from "react";
import Header from "../Layout/Header";

const Banner = () => {
  const bannerStyles = {
    position: "relative",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  };

  const videoStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div>
      <Header />
      <div style={bannerStyles}>
        <video
          style={videoStyles}
          autoPlay
          muted
          loop
          playsInline
        >
          <source
            src="https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Banner;
